

const apiconfig = require("../config/negosyonow-config");
const requestJSON = require("./requestJSON");

const getURL = (entity, action) => {
	return apiconfig.url.protocol+"://"+apiconfig.url.host+"/"+action+"/"+entity;
}


const buildOrder = (jwtToken, type, params) => {
	return requestJSON.send( getURL(type.toLowerCase(), "databuild") , "POST", params, jwtToken);
}

const buildEntityId = (pkid, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"id", "databuild") , "POST",{"id": pkid}, jwtToken);
}

const buildEntityIdList = (idlist, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"idlist", "databuild") , "POST",{"idlist": idlist}, jwtToken);
}


const buildEntitybyParam = (param, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"id", "databuild") , "POST",param, jwtToken);
}


const buildEntityIdListbyParam = (param, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"idlist", "databuild") , "POST",param, jwtToken);
}

const loadFormData = (url, jwtToken) => {
	// Retrieves submission payload (e.g. seminar registration)
	return requestJSON.send( getURL("json", "loadform") , "POST",{"url": url}, jwtToken);
}

const activateEntity = (pkid, jwtToken, entity) => {
	// Should trigger corresponding build
	return requestJSON.send( getURL(entity, "activate") , "POST",{"documentid": pkid}, jwtToken);
}


const queryData = (entity, params, jwtToken) => {
	// Internal
	/*
	// Sample
	const params = {
		*tablelist: [],
		fieldlist: [],
		*joinlist: [{table:"", condlist:[], *type:""}],
		*filters:  [[{field:"", value:"", *operation:""}]],
		*condlist: [],
		*grouping: {fieldlist:[], condlist:[]}
		*sortlist: [],

		*getallrows: false,
		*count: 20,
		*offset: 0
	};
	*/
	return requestJSON.send( getURL(entity, "query")+"/data" , "POST", params, jwtToken);
}

const getUserLocation = (jwtToken, callback) => {
	queryData("nnsellerlocation", {
			fieldlist:["NNPICKUP.nnpickup_id", "NNPICKUP.nnpickup_name"],
			joinlist: [{table:"nnpickup", condlist:["NNPICKUP.nnpickup_id=NNSELLERLOCATION.nnpickup_id"], type:"left"}],
			count:1
		}, jwtToken).then(response => {
			if (response.status === "OK") {
				if (response.records.length > 0) {
					callback(response.records[0].nnpickup_id, response.records[0].nnpickup_name);
					return;
				}
				callback(-1, "");
				return;
			}
			callback(-2, "");
		});
}


module.exports = {
	buildOrder,
	buildEntityId,
	buildEntityIdList,
	buildEntitybyParam,
	buildEntityIdListbyParam,
	loadFormData,
	getUserLocation,
	activateEntity
}
